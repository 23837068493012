import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Modules from "../components/Modules";

export default function Entry_withoutHero({ data }) {
  const entry = data.craft.entries[0];
  const modules = data.craft.entries[0].modules;

  return (
    <Layout logoColor="dark">
      <div className="no-hero-spacer"></div>

      <SEO>
        {parse(data.craft.seomatic.metaTitleContainer)}
        {parse(data.craft.seomatic.metaTagContainer)}
        {parse(data.craft.seomatic.metaLinkContainer)}
        {parse(JSON.parse(data.craft.seomatic.metaScriptContainer).script)}
        {parse(data.craft.seomatic.metaJsonLdContainer)}
      </SEO>

      <div className="title-lead">
        <h1
          dangerouslySetInnerHTML={{ __html: entry.title }}
          className="container container--1132px mb0"
        ></h1>
      </div>

      {modules && <Modules modules={modules} />}
    </Layout>
  );
};

export const query = graphql`
  query($id: [Craft_QueryArgument], $uri: String) {
    craft {
      entries(id: $id) {
        ... on Craft_pages_withoutHero_Entry {
          id
          title
          uri
          parent {
            id
            uri
            title
          }
          modules {
            ...textBlockData
            ...imageGalleryData
            ...fullwidthImageData
            ...imageGridData
            ...teamMemberGridData
            ...blogCardGridData
            ...ctaCardData
            ...imageSliderData
            ...logoGridData
            ...workCardData
            ...rollupCtaData
            ...selectableWorkCardsData
            ...imageWithContentData
            ...videoData
            ...hubspotEmbedData
            ...twoColumnTextBlockData
            ...industryResourcesGridData
          }
        }
      }

      seomatic(uri: $uri) {
        metaTitleContainer
        metaTagContainer
        metaLinkContainer
        metaScriptContainer
        metaJsonLdContainer
      }
    }
  }
`;
